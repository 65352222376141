import React, {useState} from 'react'
import {textData, ajaxUpdate} from './textData'
import {stringReplaceMask, phoneDraw, uSearch, tokenIncorrect, sSet, sGet, sDel, sName, eCheck} from 'helper-func'
import {apiBoAuthFormOrUrl, apiBoHistory, apiBoUserInfo, apiBoSubsInfo, apiBoSubsASR, apiBoUserSafe, apiBoUserInfoCheck, apiFrameGetDataCheck, apiBoHistoryCheck} from 'api-methods'

function BackOffice() {

    // 0. self
    const [errorMessage, setErrorMessage]       = useState('')
    const [popup, setPopup]                     = useState(false)
    const [popupType, setPopupType]             = useState('form')
    const [popupItem, setPopupItem]             = useState(0)
    const [popupValue, setPopupValue]           = useState(0)
    const [popupMessage, setPopupMessage]       = useState('')
    const [popupAlert, setPopupAlert]           = useState('')
    const [internalRoute, setInternalRoute]     = useState('loader')
    const [reqAuth, setReqAuth]                 = useState(false)
    const [boUserData, setBoUserData]           = useState({})
    const [boProductData, setBoProductData]     = useState({})
    const [userEmail, setUserEmail]             = useState('')
    const [userPhone, setUserPhone]             = useState('')
    let ajPost
    let useItem             = {}
    let internalToken       = sGet(sName.uBoToken)
    let internalLogin       = sGet(sName.uBoLogin)

    function clearAndReload(apiCode){if(apiCode!==200){sDel(sName.uBoToken);sDel(sName.uBoLogin);window.location.reload()}}

    // 1. auth
    const [inputAuthLogin, setInputAuthLogin] = useState('')
    const [inputAuthPass, setInputAuthPass] = useState('')
    const [inputAuthErr, setInputAuthErr] = useState('')
    const authFormLogin = (props) => {setInputAuthErr('');setInputAuthLogin(props);}
    const authFormPass = (props) => {setInputAuthErr('');setInputAuthPass(props);}
    const authReqSend = async (nextAction = false) => {
        if (inputAuthLogin + '' === '' && !loginAuth()) {
            setInputAuthErr('Не заполнен Логин')
        } else if (inputAuthPass + '' === '' && !loginAuth()) {
            setInputAuthErr('Не заполнен Пароль')
        } else if (!reqAuth) {
            setReqAuth(true)
            if (loginAuth()) {ajPost = await apiBoAuthFormOrUrl('url', {})} else {ajPost = await apiBoAuthFormOrUrl('form', {login: inputAuthLogin, pass: inputAuthPass})}
            setReqAuth(false)
            if (ajPost.code + '' !== '0') {
                setInputAuthErr('Ошибка: ' + ajPost.message + '')
                sSet(sName.uBoToken, "error")
            } else {
                sSet(sName.uBoToken, ajPost.token)
                sSet(sName.uBoLogin, inputAuthLogin)
                setInternalRoute('loader')
                if (nextAction) {
                    ajaxSearch(ajPost.token)
                }
            }
        }
    }

    // 1.5 login Auth
    function loginAuth () {
        let loginInUri = false
        if (uSearch('login') !== '' && uSearch('uuid') !== '' && uSearch('sign') !== '') {
            loginInUri = true
        }
        return loginInUri;
    }

    // 2. search
    const [inputSearchUser, setInputSearchUser] = useState('')
    const [inputSearchDog, setInputSearchDog] = useState('')
    const searchFormId = (props) => {setInputSearchUser(props);}
    const searchFormLogin = (props) => {setInputSearchDog(props);}
    const ajaxSearch = async (methodToken = '') => {
        if (
            (inputSearchDog + '' !== '' || (inputSearchUser + '' !== '' && textData.addSearchId))
            && (
                (uSearch('uuid') === '' || (uSearch('id') === '' && textData.addSearchId))
                || (uSearch('uuid') !== inputSearchDog || (uSearch('id') !== inputSearchUser && textData.addSearchId))
            )
        ) {
            window.location.href = document.location.origin + document.location.pathname + '?' + (textData.addSearchId && ('id=' + inputSearchUser + '&')) + 'uuid=' + inputSearchDog
        } else if ((!tokenIncorrect(internalToken) || methodToken !== '') && (uSearch('uuid') !== '' || (uSearch('id') !== '' && textData.addSearchId))) {
            ajPost = await apiBoUserInfo('uuid', 'id')
            if (ajPost.code === 401 || ajPost.code === 500) {
                clearAndReload(ajPost.code)
            } else {
                apiBoUserInfoCheck(ajPost).then()
                setBoUserData(ajPost.data.user)
                if (ajPost.data.user.email) {setUserEmail(ajPost.data.user.email)}
                if (ajPost.data.user.phone) {setUserPhone(ajPost.data.user.phone)}
                setInternalRoute('search')
                let ajPostTwo = await apiBoSubsInfo(ajPost.data.user.id)
                if (ajPostTwo.code === 0) {
                    apiFrameGetDataCheck(ajPostTwo).then()
                }
                if (!!ajPostTwo && ajPostTwo !== {} && !!ajPostTwo.data && !!ajPostTwo.data !== {}) {setBoProductData(ajPostTwo.data)}
            }
        }
    }

    // 2,5. all hist block hide
    function historyOperationHide () {
        let elemLength = document.getElementsByClassName('UniBOBody__userTableData-historyOperationRelative').length
        for (let i = 0, ii = elemLength; i < ii; i++) {
            document.getElementsByClassName('UniBOBody__userTableData-historyOperationRelative')[i].setAttribute('style', '')
        }
    }

    // 3. history
    const historyOperations = async (subsId) => {
        let setElementSelector = document.getElementById('productHistory_' + subsId)
        if (setElementSelector.getAttribute('style') + '' === 'null' || setElementSelector.getAttribute('style') + '' === '') {
            historyOperationHide()
        }
        ajPost = await apiBoHistory(subsId)
        if (ajPost.code === 0) {
            apiBoHistoryCheck(ajPost).then()
        }
        setPopup(true)
        setElementSelector.innerHTML = '<div class="histNoData">Нет данных</div>'
        if (!!ajPost && ajPost !== {} && !!ajPost.data && ajPost.data !== {}) {
            setElementSelector.innerHTML = drawTable(ajPost.data, {id: subsId, name: document.getElementById('productHistoryName_' + subsId).getAttribute('name')})
        }
        setElementSelector.setAttribute('style', 'display:block;')
        return ''
    }

    // 4. table
    const drawTable = (props, internalData) => {
        let outHtml = '<div class="popupHistoryOperations"><div># ' + internalData.id + ' - ' + internalData.name + '</div><div><table class="UniBO__HistTable"><thead><tr><td>№&nbsp;&nbsp;</td><td>Дата изменения</td><td>Действие</td><td>Инициатор</td></tr></thead><tbody>'
        if (props !== {}) {
            Object.keys(props).forEach((item, key) => {
                outHtml += '<tr><td>' + (1+key) + ' </td><td>' + (props[item].data ?? '-') + ' </td><td>' + (props[item].action ?? '-') + ' </td><td>' + (props[item].user ?? '-') + ' </td></tr>'
            })
        }
        outHtml += '</tbody></table></div></div>'
        return outHtml
    }

    // 5. flopProduct, productInSubs
    const flopProduct = (prdId, active, disable) => {
        historyOperationHide()
        if (disable){return;}
        setPopupItem(prdId)
        if (!active) {if (userEmail + '' === '' && userPhone + '' === '') {setPopupType('userData')} else {setPopupType('addSubs')}} else {setPopupType('delSubs')}
        setPopup(true)
    }

    function productInSubs (prdId, active = false) {
        let match = false;
        if (!!boProductData.subscriptions) {
            Object.keys(boProductData.subscriptions).forEach((item, i) => {
                if (prdId === boProductData.subscriptions[item].product.id) {
                    match = boProductData.subscriptions[item].id
                    if (active && boProductData.subscriptions[item].status !== 'active') {
                        match = false;
                    }
                }
            });
        }
        return match
    }

    // 6. subsMove
    const [popupKeyActionLock, setPopupKeyActionLock] = useState(false)
    async function subsMove() {
        let reloadPage = true
        if (!popupKeyActionLock) {
            if (popupType === 'delSubs') {
                if ((popupValue === 0 || (popupValue === 5 && popupMessage === '')) && textData.unSubs) {
                    reloadPage = false
                    setPopupAlert('Укажите причину')
                } else {
                    setPopupType('loaderPopup')
                    setPopupKeyActionLock(true)
                    ajPost = await apiBoSubsASR('suspend', {numberAnswer: ((textData.unSubs) ? popupValue : '20'), phone: "", subscription: popupItem, textAnswer: popupMessage})
                }
            } else {
                setPopupType('loaderPopup')
                setPopupKeyActionLock(true)
                ajPost = await apiBoSubsASR((productInSubs(popupItem, false) ? 'resume' : 'activate'), productInSubs(popupItem, false) ? {subscription: productInSubs(popupItem, false)} : {product: popupItem, source: "backoffice", user_id: boUserData.id})
            }
        }
        if (ajPost.code === 401 || ajPost.code === 500) {
            clearAndReload(ajPost.code)
        }
        if (reloadPage && ajPost.code + '' === '0') {window.location.reload()}
        else {
            if (!!ajPost.code && ajPost.code + '' === '-12') {
                setPopupAlert('введите данные, для подключения подписки')
                setPopupType('userData')
                setPopup(true)
            } else if (!!ajPost.message && ajPost.message) {
                setErrorMessage(ajPost.message)
                if (popup) {setPopupType('form');setPopup(false);historyOperationHide();}
            } else {
                popupCloseAndClear()
            }
        }
    }

    // 8. log out, close popup
    const ajaxLogout = () => {sDel(sName.uBoToken);window.location.reload()}
    const popupCloseAndClear = () => {if (popup) {setPopupType('form');setPopup(false);historyOperationHide();setPopupKeyActionLock(false)}}

    // 9. i'm here
    const [fastAuth, setFastAuth] = useState(false)
    if (internalRoute === 'loader') {
        if (loginAuth() && !fastAuth) {
            setFastAuth(true)
            authReqSend(true).then()
        } else if (tokenIncorrect(internalToken)) {
            setInternalRoute('loginForm')
        } else if (!loginAuth()) {
            setInternalRoute('search')
            ajaxSearch().then()
        }
    }

    function setPopupValueFunc (value) {
        setPopupAlert('')
        setPopupValue(value)
    }

    function setPopupMessageFunc (value) {
        setPopupAlert('')
        setPopupMessage(value)
    }

    const [formNoReDraw, setFormNoReDraw] = useState(false)
    if (!formNoReDraw) {
        setFormNoReDraw(true)
        setTimeout(() => {
            searchFormId(uSearch('id'))
            searchFormLogin(uSearch('uuid'))
        }, textData.ajaxUpdate)
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            authReqSend()
        }
    }

    function userDataPopupForm (inputName, inputValue) {
        if (inputName === 'email') {
            setUserEmail(inputValue)
        } else if (inputName === 'phone') {
            setUserPhone(inputValue)
        }
    }

    async function userDataReSend (popupItem) {
        if (!eCheck(userEmail, 'email')) {
            setUserEmail('')
            setPopupAlert('Не корректный E-mail !')
        } else if (stringReplaceMask(userPhone).length !== 11) {
            setUserPhone('')
            setPopupAlert('Не корректный Телефон !')
        } else {
            setPopupItem(popupItem)
            ajPost = await apiBoUserSafe({id: boUserData.id, email: userEmail, phone: userPhone})
            if (ajPost.code + '' === '0') {
                setPopupType('loaderPopup')
                ajPost = await apiBoSubsASR((productInSubs(popupItem, false) ? 'resume' : 'activate'), productInSubs(popupItem, false) ? {subscription: productInSubs(popupItem, false)} : {product: popupItem, source: "backoffice", user_id: boUserData.id})
                if (ajPost.code + '' === '0') {
                    window.location.reload()
                } else {
                    if (ajPost.message) {setErrorMessage(ajPost.message)}
                    if (popup) {setPopupType('form');setPopup(false);historyOperationHide();}
                }
            } else {
                if (ajPost.message) {setErrorMessage(ajPost.message)}
                if (popup) {setPopupType('form');setPopup(false);historyOperationHide();}
            }
        }
    }

    // 1.4. clear, resize, ajaxUpdate ↓
    const [resizeValue, setResizeValue] = useState(800)
    function heightSend () {
        let actualPageSize = document.body.clientHeight + 40
        if (resizeValue !== actualPageSize) {
            setResizeValue(actualPageSize)
            window.parent.postMessage({height: actualPageSize}, '*')
        }
    }
    const [resizeEvent, setResizeEvent] = useState(false)
    if (!resizeEvent) {
        setResizeEvent(true)
        window.addEventListener('resize', function(event) {heightSend()}, true)
    }
    setTimeout(() => {heightSend()}, ajaxUpdate)
    // 1.4. clear, resize, ajaxUpdate ↑

    return (
        <div className="UniBOFull">
            {popup && (<div className="popupBack" onClick={e => {popupCloseAndClear()}} />)}
            {popup && (<div className="popup"><div className="popupContentFull">
                {(['loaderPopup','addSubs','delSubs','userData']).indexOf(popupType) > -1 && (<div className="popupContent popupShadow">
                    {
                        popupType === 'loaderPopup' && <div><br /><div className="UniBOHeader__title">Выполнение</div><br /><div className="preLoader"><div className="loader" /></div><br /><br />&nbsp;</div>
                    }
                    {
                        popupType === 'userData' && <div>
                            <br />
                            <div className="UniBOHeader__title">У пользователя отсутствуют контактные данные</div>
                            <br />
                            <br />
                            E-mail:<br />
                            <input id="popupUserDataEmail" name="email" placeholder="Не заполнен E-mail" value={userEmail} onChange={e => userDataPopupForm(e.target.name, e.target.value)} />
                            <br />
                            <br />
                            Телефон:<br />
                            <input id="popupUserDataPhone" name="phone" placeholder="Не заполнен Телефон:" value={phoneDraw(stringReplaceMask(userPhone))} onChange={e => userDataPopupForm(e.target.name, e.target.value)} />
                            <br />
                            <br />
                            {popupAlert !== '' && <div className="UniBO__mainRemoveSubs-alert">{popupAlert}</div>}
                            <br />
                            <div className="UniBOHeader__popupKeys">
                                <div className="UniBO__mainButtonInvert" onClick={e => {popupCloseAndClear()}}>Закрыть</div>
                                <div className="UniBO__mainButton" onClick={e => {userDataReSend(popupItem)}}>Подключить</div>
                            </div>
                            <br />
                        </div>
                    }
                    {
                        popupType === 'addSubs' && <div><br /><div className="UniBOHeader__title">Подключить подписку?</div><br /><div className="UniBOHeader__popupKeys"><div className="UniBO__mainButtonInvert" onClick={e => {popupCloseAndClear()}}>Нет</div><div className="UniBO__mainButton" onClick={e => {subsMove(popupItem)}}>Да</div></div><br /></div>
                    }
                    {
                        popupType === 'delSubs' && <div>
                            <br />
                            <div className="UniBOHeader__title">Отменить подписку?</div>
                            {
                                textData.unSubs && <div className="UniBO__mainRemoveSubs">
                                    <label><input name="removeSubs" type="radio" onChange={e => setPopupValueFunc(1)} /> <div>Есть другой антивирус</div></label>
                                    <label><input name="removeSubs" type="radio" onChange={e => setPopupValueFunc(2)} /> <div>Есть проблемы с установкой и активацией</div></label>
                                    <label><input name="removeSubs" type="radio" onChange={e => setPopupValueFunc(3)} /> <div>Не устраивает цена</div></label>
                                    <label><input name="removeSubs" type="radio" onChange={e => setPopupValueFunc(4)} /> <div>Абонент не давал согласие на подключение</div></label>
                                    <label><input name="removeSubs" type="radio" onChange={e => setPopupValueFunc(5)} /> <div>Другое</div></label>
                                    {popupValue === 5 && <div><textarea placeholder="укажите причину" maxLength="250" onChange={e => setPopupMessageFunc(e.target.value)} /></div>}
                                    {popupAlert !== '' && <div className="UniBO__mainRemoveSubs-alert">{popupAlert}</div>}
                                </div>
                            }
                            <br />
                            <div className="UniBOHeader__popupKeys">
                                <div className="UniBO__mainButtonInvert" onClick={e => {popupCloseAndClear()}}>Нет</div>
                                <div className="UniBO__mainButton" onClick={e => {subsMove(popupItem)}}>Да</div>
                            </div>
                            <br />
                        </div>
                    }
                </div>)}
            </div></div>)}
            <div className="UniBO">
                {
                    (uSearch('sign') === '') && (<div className="UniBOHeader">
                        <div className="UniBOHeader__logo UniBOHeader__logo-shift">
                            <div className="UniBOHeader__mainText">Панель управления подписками</div>
                            <div className="UniBOHeader__authData-logoPass">{(internalRoute !== 'loginForm' && internalToken !== 'error') && ((internalLogin !=='') && 'Пользователь: ' + internalLogin)}</div>
                        </div>
                        <div className="UniBOHeader__authData">
                            {(internalRoute !== 'loginForm' && internalToken !== 'error') && <div className="UniBOHeader__authData-exit"><div className="UniBO__mainButton shadowLight UniBO__minWidthKey" onClick={e => ajaxLogout()}>Выход</div></div>}
                        </div>
                    </div>)
                }
                <div className="UniBOBody">
                    {
                        internalRoute === 'loader' && <div className="preLoader"><div className="loader" /></div>
                    }
                    {
                        (internalRoute === 'loginForm' || internalToken === 'error') && (<div className="UniBOBody__loginForm">
                            <div className="UniBOBody__loginForm-login"><input onKeyDown={event => handleKeyDown(event)} placeholder="Логин" value={inputAuthLogin} onChange={e => authFormLogin(e.target.value)} /></div>
                            <div className="UniBOBody__loginForm-password"><input onKeyDown={event => handleKeyDown(event)} placeholder="Пароль" type="password" value={inputAuthPass} onChange={e => authFormPass(e.target.value)} /></div>
                            <div className="UniBOBody__loginForm-button"><div className="UniBO__mainButton shadowLight" onClick={e => authReqSend()}>Авторизация</div></div>
                            <div className="UniBOBody__loginForm-err">{(internalToken === 'error' && (inputAuthErr + '' !== '' && (inputAuthErr)))}</div>
                        </div>)
                    }
                    {
                        ((internalRoute === 'search' || errorMessage !== '') && internalToken !== 'error') && (
                            <div>
                                <div className="UniBOBody__mainBlock">
                                    <div className="UniBOBody__leftBlock">
                                        {(uSearch('sign') === '') && <div>
                                            <div className="UniBOBody__titleBlock">Поиск абонентов</div>
                                            <div className="UniBOBody__searchForm">
                                                {textData.addSearchId && <div className="aTest_searchFormId UniBOBody__searchForm-dog"><input placeholder={uSearch('id') ? ('Абонент № ' + uSearch('id')) : 'Введите id абонента'} value={inputSearchUser} onChange={e => searchFormId(e.target.value)} /></div>}
                                                <div className="aTest_searchFormUuid UniBOBody__searchForm-dog"><input placeholder={uSearch('uuid') ? ('Договор № ' + uSearch('uuid')) : 'Введите № договора'} value={inputSearchDog} onChange={e => searchFormLogin(e.target.value.replace(/[^0-9A-Za-z/]+/g, ""))} /></div>
                                                <br />
                                                <div className="UniBOBody__searchForm-button"><div className="shadowLight UniBO__mainButton" onClick={e => ajaxSearch()}>Поиск</div></div>
                                            </div>
                                        </div>}

                                        {boUserData !== {} && <div>
                                            <div className={'UniBOBody__titleBlock' + ((uSearch('sign') === '') ? ' UniBOBody__brakeBlock': '')}>Данные абонента</div>
                                            <div className="UniBOBody__searchForm">
                                                {boUserData.id && <div className="UniBOBody__userData"><div className="UniBOBody__userData-textPadding">ID:</div><div>{boUserData.id}</div></div>}
                                                {boUserData.externalId && <div className="UniBOBody__userData"><div className="UniBOBody__userData-textPadding">UUID:</div><div>{boUserData.externalId}</div></div>}
                                                {boUserData.lastName && <div className="UniBOBody__userData"><div  className="UniBOBody__userData-textPadding">Фамилия:</div><div>{boUserData.lastName}</div></div>}
                                                {boUserData.firstName && <div className="UniBOBody__userData"><div  className="UniBOBody__userData-textPadding">Имя:</div><div>{boUserData.firstName}</div></div>}
                                                {(['','0','undefined'].indexOf(boUserData.balance + '') === -1) && <div className="UniBOBody__userData"><div className="UniBOBody__userData-textPadding">Баланс:</div><div>{boUserData.balance}</div></div>}
                                                {boUserData.email && <div className="UniBOBody__userData"><div  className="UniBOBody__userData-textPadding">E-mail:</div><div>{boUserData.email}</div></div>}
                                                {boUserData.phone && <div className="UniBOBody__userData"><div  className="UniBOBody__userData-textPadding">Телефон:</div><div>{phoneDraw(stringReplaceMask(boUserData.phone))}</div></div>}
                                            </div>
                                        </div>}
                                    </div>

                                    <div className="UniBOBody__rightBlock">
                                        {errorMessage === ''
                                            ? (<div>
                                                {(boProductData && boProductData.subscriptions && Object.keys(boProductData.subscriptions).length > 0) && (
                                                    <div>
                                                        <div className="UniBOBody__titleBlock">Действующие подписки абонента</div>
                                                        <div className="UniBOBody__userTableData">
                                                            <table>
                                                                <thead><tr><td>Продукт</td><td /><td className="UniBO__textCenter">Статус</td><td>Подключена</td><td>Активна&nbsp;до</td><td className="UniBO__textCenter">Действие</td></tr></thead>
                                                                <tbody>
                                                                {Object.keys(boProductData.subscriptions).length > 0 ? (
                                                                    Object.keys(boProductData.subscriptions).map((item, i) => {
                                                                        const useItem = boProductData.subscriptions[item]
                                                                        useItem.date_create = !!useItem.date_create ? useItem.date_create.split(' ')[0] : ''
                                                                        useItem.date_active_to = !!useItem.date_active_to ? useItem.date_active_to.split(' ')[0] : ''
                                                                        return <tr key={i} s_id={boProductData.subscriptions[item].id}>
                                                                            <td>{useItem.product.cover && <div className="UniBOBody__userTableData-coverBlock"  id={'productHistoryName_' + boProductData.subscriptions[item].id} name={useItem.product.name}><img className="UniBOBody__userTableData-cover" src={useItem.product.cover} alt={useItem.product.id}/><img className="UniBOBody__userTableData-coverBig" src={useItem.product.cover} alt={useItem.product.id} /></div>}{useItem.product.name} {useItem.is_hit + '' === '1' && <b>(хит)</b>}</td>
                                                                            <td><div className="UniBOBody__userTableData-historyOperation" onClick={e => historyOperations(boProductData.subscriptions[item].id)}>История&nbsp;операций</div><div id={'productHistory_' + boProductData.subscriptions[item].id} className="UniBOBody__userTableData-historyOperationRelative">&nbsp;</div></td>
                                                                            <td className="UniBO__textCenter">{useItem.status_name}</td>
                                                                            <td className="UniBO__textCenter">{useItem.date_create}</td>
                                                                            <td className="UniBO__textCenter">{useItem.date_active_to}</td>
                                                                            <td>
                                                                                <div
                                                                                    title={useItem.product.auto_prolong === 0 ? 'Данную подписку нельзя приостанавливать / возобновлять' : ''}
                                                                                    className={'UniBO__minWidthKey shadowLight ' + ((useItem.status === 'active' || useItem.status === 'fin_block') ? 'UniBO__mainButtonInvert ' : 'UniBO__mainButton ') + ((useItem.product.auto_prolong === 0 || useItem.status === 'fin_block') ? 'UniBO__mainButton-disable' : '')}
                                                                                    onClick={() => flopProduct(((useItem.status === 'active' || useItem.status === 'fin_block') ? boProductData.subscriptions[item].id : useItem.product.id), (useItem.status === 'active' || useItem.status === 'fin_block'), (useItem.product.auto_prolong === 0))}
                                                                                >{(useItem.status === 'active' || useItem.status === 'fin_block') ? 'Отключить' : 'Возобновить'}</div>
                                                                                {
                                                                                    useItem.status === 'fin_block' && <div
                                                                                        className='UniBO__minWidthKey shadowLight UniBO__mainButton UniBO__mainButton-disable'
                                                                                        onClick={() => flopProduct(useItem.product.id, false, (useItem.product.auto_prolong === 0))}
                                                                                    >Возобновить</div>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                ) : <tr><td colSpan="6">действующих подписок нет</td></tr>}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                )}
                                                {(boProductData && boProductData.products) ? (<div>
                                                    <div className={'UniBOBody__titleBlock ' + (Object.keys(boProductData.subscriptions).length && 'UniBOBody__brakeBlock')}>Продукты, доступные для подключения</div>
                                                    <div className="UniBOBody__userTableData">
                                                        <table>
                                                            <thead><tr><td>Продукт</td><td>Триал&nbsp;(дней)</td><td>Стоимость&nbsp;(&#8381;)</td><td className="UniBO__textCenter">Действие</td></tr></thead>
                                                            <tbody>
                                                            {Object.keys(boProductData.products).length > 0 ? (
                                                                Object.keys(boProductData.products).map((item, i) => {
                                                                    useItem = boProductData.products[item]
                                                                    if (useItem.is_show === 1 && textData.blockDistributor.indexOf(boProductData.products[item].distributor + '') === -1) {
                                                                        return <tr key={i}>
                                                                            <td>{useItem.cover && <div className="UniBOBody__userTableData-coverBlock"><img className="UniBOBody__userTableData-cover" src={useItem.cover}  alt={useItem.id}/><img className="UniBOBody__userTableData-coverBig" src={useItem.cover} alt={useItem.id} /></div>}{useItem.name} {useItem.is_hit + '' === '1' && <b>(хит)</b>}</td>
                                                                            <td className="UniBO__textCenter">{useItem.is_trial_product === 1 ? useItem.trial_days : '-'}</td>
                                                                            <td className="UniBO__textCenter">{parseFloat(useItem.real_price)}</td>
                                                                            <td><div className={'UniBO__minWidthKey shadowLight ' + (productInSubs(boProductData.products[item].id) ? 'UniBO__mainButtonInvert UniBOBody__disabled ' : 'UniBO__mainButton ')} onClick={e => (flopProduct(boProductData.products[item].id, false, !!productInSubs(boProductData.products[item].id)))}>Подключить</div></td>
                                                                        </tr>
                                                                    }
                                                                    return null
                                                                })
                                                            ) : <tr><td colSpan="4">продуктов нет</td></tr>}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>) : 'Ничего не найдено'}
                                            </div>)
                                            : (internalToken !== 'error' && <div className="UniBOFooter__errorMessage">
                                                <div className="UniBOFooter__errorMessage-symbol">!</div>
                                                <div className="UniBOFooter__errorMessage-head">КАТАЛОГ АНТИВИРУСОВ ВРЕМЕННО НЕДОСТУПЕН</div>
                                                <div className="UniBOFooter__errorMessage-title">Пожалуйста, попробуйте обновить страницу или зайдите позднее. Приносим извинения за доставленные неудобства.</div>
                                                <br />
                                                <div className="UniBOFooter__errorMessage-message">{errorMessage}</div>
                                                <br />
                                                <a href={document.location.href} className="UniBO__mainButton">Обновить страницу</a>
                                            </div>)}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="UniBOFooter"><div className="UniBOFooter__poweredBy">Разработано <a href="https://enaza.ru/">Enaza</a>, {new Date().getFullYear()}</div><div className="UniBOFooter__support">Служба поддержки <a href="mailto:help@enaza.ru">help@enaza.ru</a></div></div>
            </div>
        </div>
    )
}

export default BackOffice
